import Swiper from 'swiper';
import 'swiper/css/bundle';

import "@styles/pages/inspiration-hub.scss";

import "@scripts/pinia.ts";
import "@scripts/app.ts";
import '@scripts/enquiry-form.ts';
import "@scripts/htmlSlider.js";

class InspirationHubInit {
    filterTagsParent: any;
    sortByParent: any;
    tagsMenu: any;
    sortByMenu: any;
    filterTags: any;
    filters: NodeListOf<Element>;
    sortByText: any;
    tagsCheckboxes: any;
    sortByCheckboxes: any;
    filterClear: any;
    filterClose: NodeListOf<Element>;
    filterCount: number;
    filteredCards: any;
    collectionsList: HTMLElement;
    collectionHighlight: HTMLElement;
    body: HTMLBodyElement;
    theWindow: Window & typeof globalThis;
    scrollAmount: any;

    constructor() {
        this.getDOM();
        this.initFilters();
        this.initSwiper();
    }

    getDOM() {
        this.filterTagsParent = document.querySelector("#filter-tags");
        if (this.filterTagsParent) {
            this.tagsMenu = this.filterTagsParent.querySelector("ul.tags");
            if (this.tagsMenu) {
                this.tagsCheckboxes = this.tagsMenu.querySelectorAll("input[type='checkbox']");
            }
        }
                
        this.sortByParent = document.querySelector("#sort-by");
        if (this.sortByParent) {
            this.sortByMenu = this.sortByParent.querySelector("ul.sort-by");
            this.sortByText = document.querySelector("ul.sort-by > li:not(.filter-title)");
            this.sortByCheckboxes = this.sortByText.querySelectorAll("input[type='checkbox']");
        }
        
        this.filterTags = document.querySelector(".filter-menu-tags");
        if (this.filterTags) {            
            this.filterClear = this.filterTags.querySelector(".clear");
        }

        this.collectionsList = document.getElementById("collections-list");
        this.collectionHighlight = document.getElementById("collection-highlight");

        this.filters = document.querySelectorAll(".filter-menu__type > ul > li:not(.filter-title)");
        this.filterClose = document.querySelectorAll(".filter-close");

        this.body = document.querySelector("body");
        this.theWindow = window;
        this.scrollAmount;
    }

    initSwiper() {
        new Swiper(".four-cards-swiper", {
            slidesPerView: "auto",
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next-four-cards',
                prevEl: '.swiper-button-prev-four-cards',
            },
            //breakpoints: {
            //    700: {
            //        slidesPerView: "auto",
            //        spaceBetween: 16,
            //        navigation: {
            //            nextEl: '.swiper-button-next',
            //            prevEl: '.swiper-button-prev',
            //        },
            //    }
            //},
        });
    }

    initFilters() {
        this.filteredCards = [];
        this.filterCount = 0;
        this.filters.forEach(filter => {
            filter.addEventListener("click", e => {
                e.stopPropagation();
                if (window.innerWidth >= 691) {
                    this.filters.forEach(f => f.classList.remove("active"));
                    filter.classList.add("active");
                } else {
                    filter.classList.toggle("active");
                }
            });
        });
        document.addEventListener("click", () => {
            this.filters.forEach(f => f.classList.remove("active"));
        });

        this.filterClose.forEach(close => close.addEventListener("click", e => { e.stopPropagation(); this.unlockBody(); this.tagsMenu.classList.remove("active"); this.filterTagsParent.classList.remove("active"); this.sortByParent?.classList.remove("active"); this.sortByMenu?.classList.remove("active"); this.filterTags?.classList.remove("active"); }));

        this.filters.forEach(filter => {
            let subFilters = filter.querySelectorAll("ul.tags li ul li");
            let sortFilters = filter.querySelectorAll("ul.sort-by li ul li");


            subFilters.forEach(subFilter => subFilter.addEventListener("click", e => {
                e.stopPropagation();
                this.createFilter(subFilter.childNodes[0].textContent);
                this.updateFilterCounts();
            }));

            sortFilters.forEach(sortFilter => sortFilter.addEventListener("click", e => {
                e.stopPropagation();
                this.sortByText.children[0].textContent = sortFilter.textContent;
                this.sortByCheckboxes.forEach(sort => sort.checked = false);
                sortFilter.querySelector("input").checked = true;
            }));

        });
        this.filterClear?.addEventListener("click", () => {
            let filterBtns = this.filterTags.querySelectorAll("li:not(.clear, .button)");
            filterBtns.forEach(filterBtn => filterBtn.remove());
            this.filterCount = 0;
            this.filterClear.classList.remove("active");
            this.tagsCheckboxes.forEach(sort => sort.checked = false);
            this.filterTags.classList.remove("active");
            this.cards().forEach(card => card.classList.remove("hide"));
            this.collectionsList?.classList.remove('hide');
            this.collectionHighlight?.classList.remove('hide');            
            this.updateFilterCounts();
        });
        this.tagsMenu?.addEventListener("click", e => e.stopPropagation());
        this.sortByMenu?.addEventListener("click", e => e.stopPropagation());
        this.filterTagsParent?.addEventListener("click", e => { this.sortByMenu?.classList.remove("active"); this.sortByParent?.classList.remove("active"); this.tagsMenu.classList.toggle("active"); e.currentTarget.classList.toggle("active"); this.lockBody(); });
        this.sortByParent?.addEventListener("click", e => { this.tagsMenu.classList.remove("active"); this.filterTagsParent.classList.remove("active"); this.sortByMenu.classList.toggle("active"); e.currentTarget.classList.toggle("active"); this.lockBody(); });
    }

    updateFilterCounts() {
        let remainingTags = this.cards()
            .filter(c => !c.classList.contains('hide'))
            .flatMap(c => Object.values(c.dataset))
            .reduce((a, b) => {
                if (a[b]) {
                    a[b]++;
                } else {
                    a[b] = 1;
                }
                return a;
            }, {});

        this.filters.forEach(filterMenu => {
            let filterItem = filterMenu.querySelectorAll<HTMLElement>("ul.tags li ul li");
            filterItem.forEach(filter => {
                let filterInput = filter.querySelector<HTMLInputElement>('input');
                let filterSpan = filter.querySelector<HTMLSpanElement>('span');
                if (filterInput.checked || !!remainingTags[filterInput.name]) {
                    filterSpan.innerText = `(${remainingTags[filterInput.name] || 0})`;
                }
                else {
                    filterSpan.innerText = `(0)`;
                }
            });
        });
    }


    unlockBody() {
        this.body.classList.remove("lock");
        (<any>this.body).style = "";
        this.theWindow.scrollTo(0, this.scrollAmount);
    }

    lockBody() {
        this.scrollAmount = this.theWindow.scrollY;
        this.body.classList.toggle("lock");
        this.body.style.top = -this.scrollAmount + "px";
    }

    cards() {
        return <Array<HTMLElement>>Array.from(document.querySelectorAll('.card'));
    }

    clearFilters() {
        this.filterClear.classList.remove("active");
        this.filterTags.classList.remove("active");
        this.cards().forEach(card => card.classList.remove("hide"));
        this.collectionsList?.classList.remove('hide');
        this.collectionHighlight?.classList.remove('hide');
        this.updateFilterCounts();
    }

    createFilter(text) {
        if (document.getElementById(text)) {
            document.getElementById(text).remove();
            this.filterCount--;
            this.filteredCards.length = 0;
            if (this.filterCount === 0) {
                this.clearFilters();
            }
            this.filterCards();
            const filters = document.getElementsByName(`${text}`) as NodeListOf<HTMLInputElement>;
            filters.forEach(e => e.checked = false);
        } else {
            let li = document.createElement("li");
            let img = document.createElement("img");
            img.src = "/images/icons/cancel.svg";
            li.textContent = text;
            li.id = text;

            li.appendChild(img);
            img.addEventListener("click", () => {
                (img.parentNode as HTMLElement).remove();
                this.filterCount--;
                this.filteredCards.length = 0;
                if (this.filterCount === 0) {
                    this.clearFilters();
                }
                this.filterCards();
                const filters = document.getElementsByName(`${text}`) as NodeListOf<HTMLInputElement>;
                filters.forEach(e => e.checked = false);
            });
            this.filterTags.appendChild(li);
            this.filterCount++;
            if (this.filterCount >= 1) {
                this.filterClear.classList.add("active");
                this.filterTags.classList.add("active");
            }

            this.filterCards();
        }
    }

    filterCards() {
        const currentFilters = this.filterTags.querySelectorAll("li:not(.clear, .button)");
        if (currentFilters.length == 0) {
            this.clearFilters();            
        } else {
            this.collectionsList?.classList.add('hide');
            this.collectionHighlight?.classList.add('hide');
            let filterCards = this.cards();
            currentFilters.forEach(currentFilter => {
                filterCards = filterCards.filter(card =>
                    card.dataset.destinationfilter === currentFilter.textContent 
                    || card.dataset.authorfilter === currentFilter.textContent 
                    || card.dataset.holidaytypefilter === currentFilter.textContent 
                    || card.dataset.interestfilter === currentFilter.textContent);
            });
            this.filteredCards = filterCards;
            this.cards().forEach(card => card.classList.add("hide"));
            this.filteredCards.forEach(card => card.classList.remove("hide"));
        };
    }
}


new  InspirationHubInit();